import React, {Component} from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom';

import * as AUTH from './authentication';
import PostBlock from './postBlock';

class UnderMenu extends Component{
  constructor(props){
    super(props);
    this.state = {
      BODY : this.props.BODY
    }

    this.getLatestPost = this.getLatestPost.bind(this);

  }

componentDidMount(){
  var p1 =  new Promise((resolve, reject) => {
    console.log("PPPSPS")

    resolve(AUTH.INIT());
  })
  
  p1.then(() => {
    console.log("TWO")

  AUTH.getPosts().then((result) => {
    this.setState({
      ALLPOSTS : result,
      BLOCKPOSTS : result.map((x, index) => {
        return React.cloneElement(<PostBlock key={"postBlock" + index} kip={"postBlock" + index} POST={x} />)
      })
    })
  })
  })
}

  getLatestPost(){
      
  }


  render(){

  return (
    <div className="underMenu row" id="UNDERMENU">
      {this.state.BLOCKPOSTS}
    </div>
  );

  }
}

export default UnderMenu;
