import React, {Component} from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import PostBlock from './postBlock';
import * as AUTH from './authentication';


class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      LANDINGPEEK : false,
      LANDINGOUT : true,
      TEXT : null,
      LATESTPOST : null,
      SINGLEBODY : null
    }


 

  }


  componentDidMount(){
   var p1 =  new Promise((resolve, reject) => {
      console.log("PPPSPS")

      resolve(AUTH.INIT());
    })
    
    p1.then(() => {
      console.log("TWO")

    AUTH.getSinglePost().then((result) => {
      this.setState({
        LATESTPOST : result? result : null,
        SINGLEBODY : result[0] ? result[0].body.map((x, index) => {
          return <p  key={`SPP${index}`} className="singlePostParagraph" id={`SPP${index}`}>{x.paragraphBody}</p>;
        }) : null
      })
    })
    }).then(() => {
        AUTH.getPosts().then((result) => {
            this.setState({
              ALLPOSTS : result,
              BLOCKPOSTS : result.map((x, index) => {
                return React.cloneElement(<div className="col" key={"postBlock" + index}><Link to={`/${String(x.postTitle).replace(/ /g, "_")}`}><p>{x.postTitle}</p></Link></div>)
              })
            })
          })
          
    })
  }




 

  render(){

  return (
    <Router>
     <div className="mobileApp container-fluid" id="App">
        <div className="titleRow row">
            <h3 className="display-3">THINKING OF</h3>
        </div>
        <div className="slider row">
            {this.state.BLOCKPOSTS}
        </div>
    <div className="mobileMain row"> 
    
          </div>
      </div>
    </Router>
  );

  }
}

export default App;
