import React, {Component} from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom';

import * as AUTH from './authentication';

class PostBlock extends Component{
  constructor(props){
    super(props);
    this.state = {
      BODY : this.props.BODY,
      styleRef : React.createRef()
    }

    this.getLatestPost = this.getLatestPost.bind(this);

  }

componentDidMount(){
 this.setState({
  BODY : this.props.POST.body.map((x, index) => {
    return <Link to={`/${String(x.postTitle).replace(/ /g, "_")}`}> <p  key={`previewBody${index}`} className="singlePostParagraph" id={`PB${index}`}>{x.paragraphBody}</p></Link>;
  }),
  DESCRIPTION : this.props.POST.description.map((x, index) => {
    return <Link to={`/${String(x.postTitle).replace(/ /g, "_")}`}> <p key={`description${index}`} className="ds">{x.descriptionSentence}</p></Link>;
  })
 })

 console.log(this.props)

 this.state.styleRef.current.setAttribute("style", "height: " + this.state.styleRef.current.offsetWidth + "px;");
}

  getLatestPost(){
      
  }


  render(){

  return (
    <div className="postBlock col-md-3" >
      <div className="row postBlockTitleRow"   onClick={() =>{ window.location.pathname = `${String(this.props.POST.postTitle).replace(/ /g , "_")}`}} >
        <div className="postBlockTitleCol col-md-12" ref={this.state.styleRef}  id="POSTBLOCKTITLECOL">
        <h1>{this.props.POST.postTitle}</h1>
        {this.state.DESCRIPTION}
        </div>
      </div>
     
    </div>
  );

  }
}

export default PostBlock;
