import React, {Component} from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import thinking from './images/thinkingof3.gif';
import thinking2 from './images/thinkingof4.png';
import * as AUTH from './authentication';
import SinglePost from './singePost';
import UnderMenu from './underMenu';

class App extends Component{
  constructor(props){
    super(props);
    this.state = {
      x : window.matchMedia("(max-width: 768px)"),
      LANDINGPEEK : false,
      LANDINGOUT : true,
      TEXT : null,
      LATESTPOST : null,
      SINGLEBODY : null
    }


    this.underHint = this.underHint.bind(this);
    this.closeUnderHint = this.closeUnderHint.bind(this);
    this.landingTimeout = this.landingTimeout.bind(this);

    this.bringOut = this.bringOut.bind(this);

    this.landingHint = this.landingHint.bind(this);
    this.openLanding = this.openLanding.bind(this);
    this.closeLandingHint = this.closeLandingHint.bind(this);

  }


  componentDidMount(){
   var p1 =  new Promise((resolve, reject) => {
      console.log("PPPSPS")

      resolve(AUTH.INIT());
    })
    
    p1.then(() => {
      console.log("TWO")

    AUTH.getSinglePost().then((result) => {
      this.setState({
        LATESTPOST : result? result : null,
        SINGLEBODY : result[0] ? result[0].body.map((x, index) => {
          return <p  key={`SPP${index}`} className="singlePostParagraph" id={`SPP${index}`}>{x.paragraphBody}</p>;
        }) : null
      })
    })
    })
  }




  underHint(_which){


    if(_which === 1){
      document.getElementById("LANDING").classList.remove("landing3")

    document.getElementById("LANDING").classList.add("LANDINGPEEK")
    document.getElementById("LANDING").classList.remove("landing")
    
    
    document.getElementById("LINKSMENU").classList.add("linksMenuHover")
    document.getElementById("LINKSMENU2").classList.add("linksMenu2Hover")

    document.getElementById("UNDERNEATH").classList.add("underneathHover")

    document.getElementById("TLOF2").classList.toggle("TLOF2ANIM2")
    document.getElementById("TLOF2").classList.toggle("TLOF2BASE")

    document.getElementById("TLOF").classList.toggle("TLOFANIM2")
    document.getElementById("TLOF").classList.toggle("TLOFBASE")



    }else if(_which === 2){

    }

    

    

    
  }

  closeUnderHint(){
      window.clearTimeout(this.landingTimeout())
      document.getElementById("LANDING").classList.add("landing3")

      document.getElementById("LANDING").classList.remove("LANDINGPEEK");

        document.getElementById("LINKSMENU").classList.remove("linksMenuHover")
        document.getElementById("LINKSMENU2").classList.remove("linksMenu2Hover")


        document.getElementById("UNDERNEATH").classList.remove("underneathHover")

        document.getElementById("TLOF2").classList.toggle("TLOF2ANIM2")
        document.getElementById("TLOF2").classList.toggle("TLOF2BASE")

        document.getElementById("TLOF").classList.toggle("TLOFANIM2")
        document.getElementById("TLOF").classList.toggle("TLOFBASE")


  }

  landingTimeout() {
  }



  bringOut(){

    if(this.state.LANDINGOUT){
      this.setState({
        LANDINGOUT : false
      }, () => {
    document.getElementById("LANDING").classList.add("landingGone")
        document.getElementById("TLOF").classList.toggle("TLOFANIM")
        document.getElementById("TLOF").classList.toggle("TLOFBASE")

        document.getElementById("TLOF2").classList.toggle("TLOF2ANIM")
        document.getElementById("TLOF2").classList.toggle("TLOF2BASE")
  })
    } 
    
  }


  openLanding(){
    if(!this.state.LANDINGOUT){
      this.setState({
        LANDINGOUT : true
      }, () => {
        document.getElementById("LANDING").classList.add("landing3")


        document.getElementById("TLOF").classList.toggle("TLOFBASE")
        document.getElementById("TLOF").classList.toggle("TLOFANIM")

        document.getElementById("TLOF2").classList.toggle("TLOF2ANIM")
        document.getElementById("TLOF2").classList.toggle("TLOF2BASE")


      document.getElementById("LANDING").classList.remove("landingGone")
      document.getElementById("LANDING").classList.remove("LANDINGPEEK2")
      document.getElementById("RIGHTSIDEBAR").classList.remove("rightSideBarPeek")


      });
      
      

 
    }
  }


  landingHint(){
    document.getElementById("LANDING").classList.add("LANDINGPEEK2")
    document.getElementById("RIGHTSIDEBAR").classList.add("rightSideBarPeek")
  }


  closeLandingHint(){
    document.getElementById("LANDING").classList.remove("LANDINGPEEK2")
    document.getElementById("RIGHTSIDEBAR").classList.remove("rightSideBarPeek")


  }

  render(){

  return (
    <div className="App container-fluid" id="App">
    <div className="mainRow row"> 
      <div className="col-md-12">
      <div className="underneath container-fluid" id="UNDERNEATH">
        <div className="row mainRowUnderneath">
        <div className="lightUpUnderneath"></div>

          <div className="col-md-11 mainColUnderneath noScrollBar">

            <UnderMenu />
          </div>

          <div className="col-md-1 linksMenu" id="LINKSMENU" onClick={() => {this.bringOut();}} onMouseEnter={() => {if(this.state.LANDINGOUT){this.underHint(1);}else{this.underHint(2)}}} onMouseOut={() => {this.closeUnderHint()}}>
          <ul>
            <li><i className="fi-social-instagram rLinks" onClick={() => {window.location.href = "https://www.instagram.com/williamjmcmillan"}}  onMouseEnter={(event) => {event.currentTarget.classList.add("iHover"); if(this.state.LANDINGOUT){this.underHint(1);}else{this.underHint(2)}}} onMouseOut={(event) => {event.currentTarget.classList.remove("iHover"); this.closeUnderHint()}}></i></li>
            <li><i className="fi-social-facebook rLinks" onMouseEnter={() => {if(this.state.LANDINGOUT){this.underHint(1);}else{this.underHint(2)}}} onMouseOut={() => {this.closeUnderHint()}} ></i></li>
            <li><i className="fi-social-twitter rLinks" onMouseEnter={() => {if(this.state.LANDINGOUT){this.underHint(1);}else{this.underHint(2)}}} onMouseOut={() => {this.closeUnderHint()}}></i></li>
            <li><i className="fi-social-linkedin rLinks" onMouseEnter={() => {if(this.state.LANDINGOUT){this.underHint(1);}else{this.underHint(2)}}} onMouseOut={() => {this.closeUnderHint()}}></i></li>

          </ul>
            </div>
            <div className="col-md-1 linksMenu2" id="LINKSMENU2" onClick={() => {this.bringOut();}} onMouseEnter={() => {if(this.state.LANDINGOUT){this.underHint(1);}else{this.underHint(2)}}} onMouseOut={() => {this.closeUnderHint()}}>
         
            </div>
        </div>

</div>
      </div>


      <div className="landing" id="LANDING" >

        <div className="container-fluid landingContainer">
          <div className="row landingMainRow">
          <div className="lightUp"></div>

            <div className="col-md-11 readerCol noScrollBar">
              <Switch>
            <Route component={SinglePost} POST={this.state.LATESTPOST} BODY={this.state.SINGLEBODY} exact path={"/***"}  />
            </Switch>
              
            </div>

        <div id="RIGHTSIDEBAR" className=" col-md-1 rightSideBar"  >

          <div className="rightSideBarOverlay" onMouseEnter={() => {if(!this.state.LANDINGOUT){this.landingHint();}}} onMouseOut={() => {if(!this.state.LANDINGOUT){this.closeLandingHint();}}} onClick={() => {this.openLanding()}}>

          </div>
          <div className="thinkingHolder">

            <img src={thinking2} id="thinking" alt="THINKING" />
          </div>
          {/* <h1 className="display-2 TL">T</h1><br/>
          <h1 className="display-2 TL">H</h1><br/>
          <h1 className="display-2 TL">I</h1><br/>
          <h1 className="display-2 TL">N</h1><br/>
          <h1 className="display-2 TL">K</h1><br/>
          <h1 className="display-2 TL">I</h1><br/>
          <h1 className="display-2 TL">N</h1><br/>
          <h1 className="display-2 TL">G</h1><br/>
          <h1 className="display-2 TL"></h1><br/> */}
          <h1 className="display-2 TL TLOF TLOFBASE" id="TLOF">OF</h1>
          <h1 className="display-2 TL TLOF2" id="TLOF2">OF</h1>


        </div>
          
        </div>

        </div>

      </div>
     
     </div>
    </div>
  );

  }
}

export default App;
