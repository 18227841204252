import { Stitch, UserPasswordCredential, AnonymousCredential} from "mongodb-stitch-browser-sdk";
var client = Stitch.initializeDefaultAppClient("thinkingof-izsvl");

var USER = null;
client.auth.loginWithCredential(new AnonymousCredential()).then(user => {
    console.log(client.auth.isLoggedIn)
    setUser(user)
    return client;
})

export async function INIT(){

    return await client;

}

export function getLocalUser(){
    return USER;
}

export function loginEmail(email, jdf) {
                    client.auth.loginWithCredential(new UserPasswordCredential(email, jdf)).then(user => {
                        setUser(user);
                      window.location.reload();
                    });
  }
  
  export async function hasLoggedInUser() {
      return INIT().auth.isLoggedIn
    }
  
  export function getCurrentUser() {
      if(client !== null){
        return client.auth.isLoggedIn ? client.auth.user : null;

      }else{
          return null;
      }
}
  
  export function logoutCurrentUser() {
      return client.auth.logoutCurrentUser();
    }


    export function newBlogPost(_firstName, _initials, _lastName, _postTitle, _links, _images, _vori, _paragraphs, _tags, _genres, _isPublished, _date, _publishedDate){
        client.callFunction("newBlogPost", [_firstName, _initials, _lastName, _postTitle, _links, _images, _vori, _paragraphs, _tags, _genres, _isPublished, _date, _publishedDate])

    }

    export function getPosts(){
        return client.callFunction("getPosts")
    }

    export function getSinglePost(){
       return  client.callFunction("getSinglePost")
    }

    export function getThisPost(postName){
        return client.callFunction("getThis" , [postName])
    }

function setClient(app){
    client = app;
}

function getClient(){
    var p1 = new Promise((resolve, reject) => {var text = "NO APP CLIENT";resolve(text)})
    return client === null ? p1 : client;
}

function setUser(user){
    USER = user;
}