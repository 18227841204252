import React, {Component} from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom';

import App from './App';

class Routes extends Component{
  constructor(props){
    super(props);
    this.state = {
          }

        }


  render(){

  return (
    <Router>
        <Route  path="/*" component={App} />

    </Router>
  );

  }
}

export default Routes;
