import React, {Component} from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router'
import { BrowserRouter as Router, Link } from 'react-router-dom';

import * as AUTH from './authentication';

class SinglePost extends Component{
  constructor(props){
    super(props);
    this.state = {
      BODY : this.props.BODY
    }

    this.getLatestPost = this.getLatestPost.bind(this);

  }

  componentDidMount(){
    if(String(this.props.location.pathname).length>1){
      AUTH.getThisPost(String(this.props.location.pathname).split("/")[1].replace(/_/g, " ")).then((result) => {
        this.setState({
          LATESTPOST : result,
          BODY : result.body.map((x, index) => {
            return <p  key={`SPP${index}`} className="singlePostParagraph" id={`SPP${index}`}>{x.paragraphBody}</p>;
          }),
          TITLE: result.postTitle
  
        })
      })
    }
  }

componentDidUpdate(prevProps){
  


    if(this.props.POST !== prevProps.POST){
    this.setState({
      LATESTPOST : this.props.POST,
      BODY : this.props.POST.body.map((x, index) => {
        return <p  key={`SPP${index}`} className="singlePostParagraph" id={`SPP${index}`}>{x.paragraphBody}</p>;
      }),
      TITLE: this.props.POST.postTitle
    })
   
  } 
 
  


}

  getLatestPost(){
      
  }


  render(){

  return (
    <div className="page" contentEditable={true} id="PAGE">
      <h1>{this.state.TITLE}</h1>
    {this.state.BODY}
    </div>
  );

  }
}

export default SinglePost;
